<template>
  <div class="mypaper" @click="popIndex = -1">
    <el-form ref="form" :model="paperForm" label-width="100px" label-suffix="：" :inline="true">
      <el-form-item label="试卷状态">
        <span 
          class="status-btn"
          :class="{active: item.id === paperForm.status}" 
          v-for="item in paperStatus"
          :key="item.id"
          @click="handleClickStatus(item.id)"
        >{{item.title}}</span>
      </el-form-item>
      <el-row>
        <el-form-item label="创建日期">
          <el-date-picker
            v-model="paperForm.create_time_start"
            value-format="yyyy-MM-dd HH:mm:ss"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :editable="app.isPad ? false : true"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="所属年级">
          <el-select v-model="paperForm.grade_id" placeholder="请选择所属年级">
            <el-option :label="item.title" :value="item.id" v-for="item in gradeList"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="试卷名称">
          <el-input v-model="paperForm.title" placeholder="请输入试卷名称"></el-input>
        </el-form-item>
        <el-form-item label="试卷类型">
          <el-select v-model="paperForm.report_type" placeholder="请选择试卷类型">
            <el-option label="全部" value="1,2"></el-option>
            <el-option label="考卷" value="1"></el-option>
            <el-option label="作业" value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="">
          <el-button type="primary" class="search-btn" @click="queryList">查询</el-button>
          <el-button @click="reset">重置</el-button>
        </el-form-item>
      </el-row>
    </el-form>
    <div v-loading="loading">
      <div class="paper-item" v-for="(item, index) in jobList" :class="{isPad: app.isPad}" :key="index">
        <div class="fr item-header">
          <div class="fr item-header-left">
            <span class="header-left-title">{{item.title}}</span>
            <span class="header-left-grade">{{item.grade_name}}</span>
          </div>
          <span v-if="item.status === 2" class="item-header-status valid">已过期</span>
        </div>
        <div class="item-time">{{item.created_at}}</div>
        <div class="item-footer fr">
          <div class="item-footer-left fr">
            <div class="footer-left-type">类型：<span class="item-footer-type">{{item.report_type == 1 ? '考卷' : '作业'}}</span></div>
            <div class="footer-left-type fr" v-show="item.report_type === 1">
              难度：
              <Star :val="item.difficulty" />
            </div>

            <div>限时：<span class="item-middle-desc">{{item.test_duration}}分钟</span></div>
            <div v-show="item.report_type === 1">总分：<span class="item-middle-desc">{{item.score}}分</span></div>
            <div v-show="item.report_type === 1">得分：<span class="item-middle-desc last">{{item.student_score}}分</span></div>
          </div>
          <div class="item-footer-right">
            <span class="item-right-btn tc disabledstatus" v-if="item.status === 3">未开始</span>
            <span class="item-right-btn tc" @click="startTest(item)" v-if="item.status === 0">开始测试</span>
            <span class="item-right-btn tc" @click.stop="popIndex = index" v-if="item.status === 1">操作</span>
            <div class="tips fc" v-if="popIndex === index && item.status === 1" @click.stop="() => null">
              <div class="sjx"></div>
              <div class="fr btns">
                <el-button type="text" @click="paperFenxi(item)">试卷分析</el-button>
                <el-button type="text" :loading="studyLoading" @click="intensiveStudy(item)" v-if="item.correct_rate >= 80 && !item.streng_generated">强化提高</el-button>
                <el-button type="text" :loading="wrongLoading" @click="wrongTopic(item)" v-if="item.correct_rate < 100 && !item.mistake_generated">错题巩固</el-button>
              </div>
            </div>
          </div>
        </div>
        <p v-if="item.status === 3" class="start_time">{{item.start_time}}</p>
      </div>
      <pagination
        :total="total"
        :page.sync="current_page"
        :size.sync="page_size"
        @handleQueryList="queryList"
      />
    </div>
  </div>
</template>

<script>
import {paperList, wrongPaperDetail, studyPaperDetail} from 'api'
import { ERROR_CODE, PAPER_STATUS_LIST, GRADE_LIST } from 'utils/config'

import Star from 'comps/star'
import Pagination from 'comps/pagination'

export default {
  data() {
    return {
      paperForm: {
        // 试卷状态
        status: null,
        // 创建时间-结束时间
        create_time_start: [],
        // 所属年级
        grade_id: null,
        // 试卷名称
        title: '',
        // 试卷类型
        report_type: '1,2',
      },
      // 作业列表
      jobList: [],
      current_page: 1,
      page_size: 10,
      loading: false,
      // 试卷状态
      paperStatus: PAPER_STATUS_LIST,
      // 显示弹出窗的索引
      popIndex: -1,
      total: 0,
      // 错题巩固的loading
      wrongLoading: false,
      // 强化提高的loading
      studyLoading: false,
      // 所属年级
      gradeList: GRADE_LIST,
    }
  },
  components: {
    Star,
    Pagination
  },
  created () {
    this.copyPaperForm = {...this.paperForm}
    this.queryList()
  },
  inject: ['app'],
  methods: {
    // 选择状态
    handleClickStatus(id) {
      this.paperForm.status = id
      this.queryList()
    },
    // 重置
    reset() {
      this.paperForm = {...this.copyPaperForm}
      this.queryList()
    },
    // 开始测试
    startTest({report_id, difficulty}) {
      this.$router.push({
        path: '/starttest',
        query: {
          id: report_id, 
          diff: difficulty
        }
      })
    },
    // 试卷分析
    paperFenxi({report_id}) {
      this.$router.push({
        path: '/paperfenxi',
        query: {
          id: report_id
        }
      })
    },
    // 错题巩固
    async wrongTopic({report_id}) {
      this.wrongLoading = true
      let {error, data = {}} = await wrongPaperDetail({report_id})
      this.wrongLoading = false
      if (error === ERROR_CODE) {
        let id = data.new_report_id
        this.$router.push({
          path: '/wrongtopic',
          query: {
            id,
          }
        })
      }
    },
    // 强化提高
    async intensiveStudy({report_id}) {
      this.studyLoading = true
      let {error, data = {}} = await studyPaperDetail({report_id})
      this.studyLoading = false
      if (error === ERROR_CODE) {
        let id = data.new_report_id
        this.$router.push({
          path: '/intensivestudy',
          query: {
            id,
          }
        })
      }
    },
    // 作业列表
    async queryList() {
      this.loading = true
      let {create_time_start, ...args} = this.paperForm
      if (create_time_start && create_time_start.length !== 0) {
        args.create_time_start = create_time_start[0]
        args.create_time_end = create_time_start[1]
      }
      let {error, data} = await paperList({
        current_page: this.current_page,
        page_size: this.page_size,
        ...args
      })
      this.loading = false
      if (error === 0) {
        this.jobList = data.list || []
        this.total = data.total_items
      }
    }
  },
}
</script>

<style lang="scss" scoped>
.mypaper {
  .el-form {
    margin-top: 40px;
    margin-bottom: 20px;
    .status-btn {
      padding: 8px 10px;
      border-radius: 6px;
      color: #555;
      font-size: 16px;
      background: transparent;
      cursor: pointer;
      margin-right: 20px;
      &.active {
        background: #E9E9E9;
        color: #333;
      }
    }
    .search-btn {
      background: #68B8E6;
      border-color: #68B8E6;
      &:active {
        background:#5db4e6;
        outline: 0;
      }
    }
  }
  .paper-item {
    background: white;
    padding: 24px;
    margin-bottom: 20px;
    border-radius: 12px;
    &.isPad {
      .header-left-title {
        font-size: 20px !important;
      }
      .item-header-status {
        font-size: 14px !important;
      }
      .item-footer {
        font-size: 16px !important;
      }
      .footer-left-type {
        margin-right: 0 !important;
      }
      .item-middle-desc {
        margin-right: 0 !important;
      }
      .item-footer-left {
        flex-grow: 1;
        margin-right: 15px;
        justify-content: space-between;
      }
    }
    .item-header {
      justify-content: space-between;
      align-items: center;
      .item-header-left {
        align-items: center;
        .header-left-title {
          color: #333;
          font-size: 22px;
          margin-right: 35px;
        }
        .header-left-grade {
          color: #5DAEE6;
          font-size: 16px;
        }
      }
      .item-header-status {
        color: #E24D54;
        font-size: 16px;
        &.valid {
          color: gray;
        }
      }
    }
    .item-time {
      margin-top: 9px;
      font-size: 14px;
      color: #999;
    }
    .item-footer {
      justify-content: space-between;
      align-items: center;
      font-size: 18px;
      color: #999;
      margin-top: 12px;
      .item-footer-left {
        align-items: center;
        .footer-left-type {
          margin-right: 50px;
          align-items: center;
          .item-footer-type {
            color: #333;
          }
        }
        .item-middle-desc {
          margin-right: 50px;
          color: #333;
          &.last {
            margin-right: 0;
          }
        }
      }
      .item-footer-middle {

      }
      .item-footer-right {
        position: relative;
        .item-right-btn {
          width: 98px;
          height: 30px;
          line-height: 30px;
          background: #5DAEE6;
          display: inline-block;
          color: white;
          border-radius: 16px;
          font-size: 14px;
          cursor: pointer;
        }
        .disabledstatus{
          background: #999;
        }
        .tips {
          position: absolute;
          right: 0;
          top: 38px;
          align-items: flex-end;
          .sjx {
            width: 0;
            height: 0;
            border-left: 5px solid transparent;
            border-right: 5px solid transparent;
            border-bottom: 10px solid #6BC3E8;
            position: relative;
            right: 40px;
          }
          .btns {
            background: #6BC3E8;
            justify-content: space-between;
            color: white;
            border-radius: 10px;
            .el-button {
              color: white;
              font-size: 18px;
              border-radius: initial;
              width: 105px;
            }
          }
        }
      }
    }
    .start_time{
      width: 100%;
      text-align: right;
      margin-top: 10px;
      color:#999;
    }
  }
}
</style>