<template>
  <el-pagination
    v-if="total > 0"
    background
    layout="total, sizes, prev, pager, next, jumper"
    :page-sizes="[10, 20, 50, 100]"
    :total="total"
    :current-page.sync="currentPage"
    :page-size.sync="pageSize"
    @size-change="handleSizeChange"
    @current-change="handleCurrentChange"
  >
  </el-pagination>
</template>

<script>
export default {
  data() {
    return {
      currentPage: this.page,
      pageSize: this.size,
    };
  },
  props: {
    page: {
      type: Number,
      default: 1,
    },
    size: {
      type: Number,
      default: 10,
    },
    total: {
      type: Number,
    },
  },
  watch: {
    page(val) {
      this.currentPage = val;
    },
    size(val) {
      this.pageSize = val;
    },
  },
  methods: {
    handleCurrentChange(val) {
      this.update("page", this.currentPage);
    },
    handleSizeChange(val) {
      this.$emit("update:size", this.pageSize);
      this.$emit("update:page", 1);
      this.$emit("handleQueryList");
    },
    update(key, value) {
      this.$emit(`update:${key}`, value);
      this.$emit("handleQueryList");
    },
  },
};
</script>